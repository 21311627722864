import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { StyledHeader } from 'shared-ui/src/components/ui/Header/styles';
import { Button, Container, ThemeModeToggle } from 'shared-ui';
import bodyClassName from 'shared-ui/src//hooks/bodyClassName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import {
  StyledHeaderLogo,
  StyledHeaderMenuLeft,
  StyledHeaderMenuRight,
  StyledHeaderThemeToggle, StyledMobileHeader
} from './styles';
import Drawer from '../Drawer';
import usePortal from 'react-useportal';

const HeaderComponent = forwardRef(
  (
    {
      children,
      type,
      flush,
      center,
      menuRight,
      menuLeft,
      mobileMenu,
      logo,
      theme,
      innerWidth,
      responsive,
      fixed: fixedProp,
      isMobile,
      ...rest},
    ref
  ) => {
    const { Portal } = usePortal();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    let { header: { showThemeSwitcher, fixed, mobileBreakpoint } } = theme;
    fixed = (fixedProp) ? fixedProp : fixed;
    bodyClassName(fixed ? 'header header-fixed' : 'header');

    const attrs = {
      fixed
    };

    const ThemeToggle = useCallback(() => (
      <>
        {(showThemeSwitcher) && (
          <StyledHeaderThemeToggle attrs={{isMobile}}>
            <ThemeModeToggle/>
          </StyledHeaderThemeToggle>
        )}
      </>
    ), [showThemeSwitcher, isMobile]);

    const HeaderItems = useCallback(() => {
      return (
        <>
          {(menuLeft !== null && menuLeft !== undefined) && <StyledHeaderMenuLeft>{menuLeft}</StyledHeaderMenuLeft>}
          {(menuRight !== null && menuRight !== undefined) && <StyledHeaderMenuRight>{menuRight}</StyledHeaderMenuRight>}
          <ThemeToggle/>
        </>
      )
    }, []); // TODO: Needs to be [menuLeft, menuRight] but it re-renders and no animation

    return (
      <StyledHeader ref={ref} attrs={attrs}>
        <Container center={center} type={type} flush={flush}>
          {(logo !== null && logo !== undefined) && <StyledHeaderLogo>{logo}</StyledHeaderLogo>}
          {!isMobile && <HeaderItems/>}
          {(isMobile && mobileMenu) && (
            <>
              <StyledHeaderMenuRight>
                <Button size="sm" icon={<FontAwesomeIcon icon={faBars}/>} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>Menu</Button>
              </StyledHeaderMenuRight>
              <Portal>
                <Drawer
                  open={mobileMenuOpen}
                  position="right"
                  onDismiss={() => setMobileMenuOpen(false)}
                  size="75%"
                >
                  <StyledMobileHeader>
                    <ThemeToggle/>
                    {mobileMenu}
                  </StyledMobileHeader>
                </Drawer>
              </Portal>
            </>
          )}
        </Container>
      </StyledHeader>
    );
  }
);

export default HeaderComponent;
