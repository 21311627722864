import React, { forwardRef, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useFormContext, Controller } from 'react-hook-form';
import {
  StyledFormItem, StyledFormItemLabel, StyledFormItemMessage,
} from './styles';
import { fieldErrorMessage, hasErrors, initializeBasicRules } from '../../../utils/form';

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

const FormItemComponent = forwardRef(
  (
    {
      label,
      hideLabel,
      name,
      info,
      className,
      style,
      render,
      rules,
      defaultValue,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef();
    const { control, errors } = useFormContext();
    const error = fieldErrorMessage(errors, name);
    const basicRules = initializeBasicRules(rules, label);

    const attrs = {
      info: (info !== null && info !== undefined),
      error: (error !== null && error !== undefined)
    };

    const duration = 300;

    const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
    };

    return (
      <StyledFormItem attrs={attrs} style={style}>
        {(label && hideLabel === false) && <StyledFormItemLabel attrs={attrs}>{label}</StyledFormItemLabel>}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={basicRules}
          onFocus={() => inputRef.current.focus()}
          render={({ onChange, onBlur, value}) => render({onChange, onBlur, value, name, ref: inputRef})}
        />
        <Transition in={(attrs.error || attrs.info)} timeout={duration}>
          {state => (
            <StyledFormItemMessage attrs={attrs} style={{...defaultStyle, ...transitionStyles[state]}}>
              {attrs.error ? error : info}
            </StyledFormItemMessage>
          )}
        </Transition>
      </StyledFormItem>
    );
  }
);

export default FormItemComponent;
