import { atomFamily } from 'recoil';

export const defaultFirestoreState = {
  loading: true,
  error: null,
  data: null
};

export const defaultFirestoreDataState = {
  loading: true,
  error: null,
  data: null
};

export const firestoreState = atomFamily({
  key: 'firestoreState',
  default: defaultFirestoreState,
  dangerouslyAllowMutability: true
});

export const firestoreDataState = atomFamily({
  key: 'firestoreDataState',
  default: defaultFirestoreDataState,
});

// defaultData => (defaultData) ? { ...defaultFirestoreData, data: defaultData } : defaultFirestoreData,
