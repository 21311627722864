import { atom } from 'recoil';

export const defaultAuthState = {
  loading: true,
  error: null,
  user: null,
  claims: null,
  loggedIn: false
};

export const defaultAuthDataState = {
  loading: true,
  error: null,
  user: null,
  claims: null,
  loggedIn: false
};

export const authState = atom({
  key: 'authState',
  default: defaultAuthState,
  dangerouslyAllowMutability: true
});

export const authDataState = atom({
  key: 'authDataState',
  default: defaultAuthDataState
});

// defaultData => (defaultData) ? { ...defaultFirestoreData, data: defaultData } : defaultFirestoreData,
