import PropTypes from 'prop-types';
import FormItem from './component';
import { defaultPropTypes, inputComponentPropType, stringOrComponentPropType } from '../propTypes';

FormItem.defaultProps = {
  defaultValue: '',
  hideLabel: false,
  rules: {}
};

FormItem.propTypes = {
  rules: PropTypes.object,
  name: PropTypes.string,
  control: PropTypes.object,
  render: PropTypes.func,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  defaultValue: inputComponentPropType,
  info: stringOrComponentPropType,
  ...defaultPropTypes
};

export default FormItem;
