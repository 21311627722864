export const baseRoutePaths = {
  HOME: '/',
  APP: 'app',
  SIGNUP: 'signup',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  ADMIN: 'admin',
  EXERCISES: 'exercises',
  NEW_EXERCISE: 'new',
  WORKOUTS: 'workouts',
  WORKOUT_PREVIEW: 'preview',
  NEW_WORKOUT: 'new'
};

export const appRoute = (path = null) => `/${baseRoutePaths.APP}${(path) ? `/${path}` : ``}`;
export const appAdminRoute = (path = null) => `/${baseRoutePaths.ADMIN}${(path) ? `/${path}` : ``}`;
