import Menu from 'shared-ui/src/components/ui/Menu/component';
import { withTheme } from 'styled-components';

Menu.defaultProps = {
  menuItems: [],
  type: 'horizontal',
  link: null,
  currentPath: ''
};

export default withTheme(Menu);
