import React, { useRef, useEffect } from 'react';
import FirebaseContext from 'react-recoil-firebase/src/context/firebase';
import { useAuthStateData } from 'react-recoil-firebase/src/hooks/auth/useAuthState';
import ProfileListener from '../../listeners/ProfileListener';
import { defaultFirebaseProviderConfig } from './constants';
import { createUser, login } from '../../helpers/auth';

const FirebaseProviderComponent = ({config, firebase, children, ...rest}) => {
  config = { ...defaultFirebaseProviderConfig, ...config };
  const context = useRef({
    firebase,
    config,
    login: (options) => login(firebase, config, options),
    createUser: (options) => createUser(firebase, config, options)
  });
  const { loggedIn, user, claims } = useAuthStateData(context.current.firebase.auth(), { listen: true, enableClaims: config.enableClaims });
  const loggedInRef = useRef(false);

  useEffect(() => {
    if(loggedIn !== loggedInRef.current) {
      loggedInRef.current = loggedIn;
      console.log('Auth has changed. Logged in: ' + loggedIn);

      // Handle redirects...
      if(loggedIn) {
        console.log({claims, user, loggedIn});
      } else {

      }

    }
  }, [loggedIn]);

  return (
    <FirebaseContext.Provider value={context.current}>
      {loggedIn && user && <ProfileListener firebase={context.current.firebase} config={config} user={user}/>}
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProviderComponent;
