import React from 'react';
import { navigate } from '@reach/router';
import { withFirebase } from 'react-recoil-firebase';
import { useAuthStateData } from 'react-recoil-firebase/src/hooks/auth/useAuthState';
import { Heading, Loader } from 'shared-ui';
import PrivateLayout from '../../../../layouts/PrivateLayout';
import PublicLayout from '../../../../layouts/PublicLayout';

const Route = ({privateRoute, adminRoute, firebase, component: Component, ...rest}) => {
  if(!privateRoute) return <PublicLayout><Component {...rest}/></PublicLayout>;

  const { loggedIn, claims, loading } = useAuthStateData(firebase.auth());
  const isAdmin = (claims && claims.admin);

  if(loading) return <Loader overlay={false} fixed={true}/>;
  if(loggedIn) {
    if(adminRoute && !isAdmin) return <Heading>Sorry, you do not have access to this page</Heading>;
    return <PrivateLayout isAdmin={isAdmin}><Component {...rest}/></PrivateLayout>;
  } else {
    navigate('/app/login');
  }

  return null;
};

export default withFirebase(Route);
