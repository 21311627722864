import { useRecoilState, useSetRecoilState } from 'recoil';
import { firestoreDataState, defaultFirestoreDataState, firestoreState, defaultFirestoreState } from '../../../atoms/firestore';
import { snapshotToData } from './helpers';
import isEqual from 'lodash/isEqual';

export default ({key, idField = 'id', defaultData = null, stateToDataState}) => {
  stateToDataState = (typeof stateToDataState === 'function') ? stateToDataState : snapshotToData;
  const [state, setFirestoreState] = useRecoilState(firestoreState(key));
  const setFirestoreDataState = useSetRecoilState(firestoreDataState(key));
  const { data, loading, error } = state;

  const reset = () => {
    setFirestoreState({...defaultFirestoreState, data: defaultData});
    setFirestoreDataState({...defaultFirestoreDataState, data: defaultData});
  };

  const setError = (error) => {
    setFirestoreState((oldState) => ({...oldState, error, loading: false}));
    setFirestoreDataState((oldState) => ({...oldState, error, loading: false}));
  };

  const setValue = (snapshot, isSnapshot = true) => {
    const newData = (isSnapshot) ? stateToDataState(snapshot, idField) : snapshot;
    if(data && isEqual(data, newData)) return;
    setFirestoreState((oldState) => ({...oldState, loading: false, data: snapshot}));
    setFirestoreDataState((oldState) => ({...oldState, loading: false, data: newData}));
  };

  return {
    error,
    loading,
    reset,
    setError,
    setValue,
    data
  };
};
