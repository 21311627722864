import React from 'react';
import styled from 'styled-components';
import { transition } from '../../../utils/mixins';

export const drawerContentTransforms = {
  top: 'translateY(-100%)',
  right: 'translateX(100%)',
  bottom: 'translateY(100%)',
  left: 'translateX(-100%)'
};

export const drawerContentTransformsDefault = {
  top: 'translateY(0%)',
  right: 'translateX(0%)',
  bottom: 'translateY(0%)',
  left: 'translateX(0%)'
};

export const drawerContentTransitionStyles = (position) => ({
  entering: { transform: drawerContentTransforms[position] },
  entered:  { transform: drawerContentTransformsDefault[position] },
  exiting:  { transform: drawerContentTransforms[position] },
  exited:  { transform: drawerContentTransforms[position] },
});

export const drawerOverlayTransitionStyles = {
  entering: { opacity: 0 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0, display: 'none' }
};

export const placements = {
  top: {
    top: 0,
    right: 0,
    left: 0
  },
  right: {
    top: 0,
    right: 0,
    bottom: 0
  },
  bottom: {
    right: 0,
    bottom: 0,
    left: 0
  },
  left: {
    top: 0,
    bottom: 0,
    left: 0
  }
};

export const StyledDrawerWrapper = styled.div`
  display: block;
  width: ${({attrs}) => ((attrs.position !== 'top' && attrs.position !== 'bottom') && attrs.size) ? attrs.size : '300px'};
  height: ${({attrs}) => ((attrs.position === 'top' || attrs.position === 'bottom') && attrs.size)? attrs.size : '100%'};
`;

export const StyledDrawerOverlay = styled.div`
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  opacity: 0;
  ${({theme: { drawer }}) => drawer.transition && transition(drawer.transition)}
`;

export const StyledDrawerContent = styled.div`
  display: block;
  box-sizing: border-box;
  position: fixed;
  z-index: 16;
  width: ${({attrs}) => ((attrs.position !== 'top' && attrs.position !== 'bottom') && attrs.size) ? attrs.size : '300px'};
  overflow-x: hidden;
  overflow-y: auto;
  background: ${({attrs: { drawerMode }, theme: { drawer }}) => (drawerMode) ? drawer.theme[drawerMode].background : drawer.background };
  box-shadow: -10px 0 10px rgba(0,0,0,0.19);
  ${({theme: { drawer }}) => drawer.transition && transition(drawer.transition)}
  ${({attrs}) => placements[attrs.position]}
`;
