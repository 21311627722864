import styled, { css } from 'styled-components';
import {
  sizeToStyle,
  cssVar
} from 'shared-ui/src/utils/utils';

const logoStyles = ({attrs, theme: { logo }}) => {
  const { width, height } = logo;

  return css`
    svg, img {
      width: ${sizeToStyle(width)};
      height: ${sizeToStyle(height)};
    }
    
    .light-logo { display: ${cssVar('light-mode', 'logo')} }
    .dark-logo { display: ${cssVar('dark-mode', 'logo')} }
    
  `;
};

export const StyledLogo = styled.div`
  ${props => logoStyles(props)}
`;
