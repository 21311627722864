import React from 'react';
import Header from '../Header';
import { appRoute, baseRoutePaths } from '../../../constants/routes';
import { Menu } from 'shared-ui/menu';
import { Link, useLocation } from '@reach/router';
import { withFirebase } from 'react-recoil-firebase';

const PublicLayout = ({children, firebase}) => {
  const { pathname } = useLocation();

  const menuLeft = [
    {
      id: 'home',
      label: 'Home',
      to: baseRoutePaths.HOME
    }
  ];

  const menuRight = [
    {
      id: 'live',
      label: 'Live Workout',
      to: appRoute()
    },
    {
      id: 'login',
      label: 'Login',
      to: appRoute(baseRoutePaths.LOGIN)
    },
    {
      id: 'signup',
      label: 'Sign Up',
      to: appRoute(baseRoutePaths.SIGNUP)
    }
  ];

  return (
    <div>
      <Header
        menuRight={<Menu menuItems={menuRight} currentPath={pathname} link={Link}/>}
        menuLeft={<Menu menuItems={menuLeft} currentPath={pathname} link={Link}/>}
        menuBottom={<Menu menuItems={menuRight} link={Link} currentPath={pathname}/>}
        mobileMenu={<Menu type="vertical" menuItems={menuRight} link={Link} currentPath={pathname}/>}
      />
      {children}
    </div>
  );
};

export default withFirebase(PublicLayout);
