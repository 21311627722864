import React from 'react';
import { Header as HeaderUi } from 'shared-ui/header';
import { Logo as LogoUi } from 'shared-ui/logo';

const Header = ({menuLeft, menuRight, mobileMenu, link}) => {
  return (
    <HeaderUi
      menuLeft={menuLeft}
      menuRight={menuRight}
      mobileMenu={mobileMenu}
      link={link}
      logo={<LogoUi to={`/`} />}
    />
  );
};

export default Header;
