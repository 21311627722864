import React, { forwardRef, useEffect } from 'react';
import { StyledMenu, StyledMenuItem } from 'shared-ui/src/components/ui/Menu/styles';
import ConditionalWrapper from '../../helpers/ConditionalWrapper';
import { StyledMenuItemLink, StyledMenuItemOnly } from './styles';

const isExternal = (to) => to && (to.includes('http://') || to.includes('https://'));

const MenuComponent = forwardRef(
  (
    {
      menuItems,
      type,
      currentPath,
      link: Link,
      ...rest},
    ref
  ) => {
    if(!menuItems.length) {
      return null;
    }

    const attrs = {
      type
    };

    const as = (Link) ? (Link) : 'a';

    return (
      <StyledMenu ref={ref} attrs={attrs}>
        {menuItems.map(item => (
          <StyledMenuItem key={item.id} attrs={attrs}>
            <ConditionalWrapper
              condition={(item.to)}
              wrapper={(children) => (
                <StyledMenuItemLink
                  attrs={{...attrs, hasClickEvent: true, active: (currentPath === item.to)}}
                  as={isExternal(item.to) ? 'a' : as}
                  to={isExternal(item.to) ? undefined : item.to}
                  href={isExternal(item.to) ? item.to : undefined}
                >
                  {children}
                </StyledMenuItemLink>
              )}
              defaultWrapper={(children) =>
                <StyledMenuItemOnly
                  attrs={{...attrs, hasClickEvent: (item.onClick && typeof item.onClick === 'function'), active: (currentPath === item.to)}}
                  onClick={(e) => {
                    if(item.onClick && typeof item.onClick === 'function') {
                      item.onClick(e);
                    }
                  }}>
                  {children}
                </StyledMenuItemOnly>}
            >
              {item.label}
            </ConditionalWrapper>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    );
  }
);

export default MenuComponent;
