import React from 'react';
import Header from '../Header';
import { appAdminRoute, appRoute, baseRoutePaths } from '../../../constants/routes';
import { Menu } from 'shared-ui/menu';
import { Link, useLocation } from '@reach/router';
import { withFirebase } from 'react-recoil-firebase';

const PrivateLayout = ({children, firebase, isAdmin}) => {
  const { pathname } = useLocation();

  let menuItems = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      to: appRoute(baseRoutePaths.DASHBOARD)
    },
    {
      id: 'logout',
      label: 'Logout',
      onClick: () => firebase.auth().signOut()
    }
  ];

  if(isAdmin) {
    menuItems = [
      ...menuItems,
      {
        id: 'exercises',
        label: 'Exercises',
        to: appAdminRoute(baseRoutePaths.EXERCISES)
      },
      {
        id: 'workouts',
        label: 'Workouts',
        to: appAdminRoute(baseRoutePaths.WORKOUTS)
      }
    ]
  }

  return (
    <div>
      <Header
        menuRight={<Menu menuItems={menuItems} link={Link} currentPath={pathname}/>}
        mobileMenu={<Menu type="vertical" menuItems={menuItems} link={Link} currentPath={pathname}/>}
      />
      {children}
    </div>
  );
};

export default withFirebase(PrivateLayout);
