import React from 'react';
import styled, { css } from 'styled-components';
import { pluckResponsiveSizing, pluckTextSizing } from '../../../utils/utils';

export const StyledFormItem = styled.div`
  position: relative;
  margin-bottom: ${({theme}) => pluckResponsiveSizing(theme, 'spacing', 'base')};
  color: ${props => props.theme.global.colors['text'][props.theme.mode]};
`;

export const StyledFormItemLabel = styled.div`
  margin-bottom: ${({theme}) => pluckResponsiveSizing(theme, 'spacing', 'xs')};
  color: ${props => props.theme.global.colors['text'][props.theme.mode]};
`;

export const StyledFormItemMessage = styled.div`
  color:${({theme, attrs: { error }}) => theme.global.colors[`status-${error ? `error` : `info`}`]};
  text-align: right;
  position: absolute;
  right: 0;
  font-size: ${({theme}) => pluckResponsiveSizing(theme, 'fontSize', 'sm')};
`;
