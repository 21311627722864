import Logo from 'shared-ui/src/components/ui/Logo/component';
import { withTheme } from 'styled-components';

Logo.defaultProps = {
  image: null,
  to: '/',
  link: null
};

export default withTheme(Logo);
