import React, { forwardRef } from 'react';
import { Transition } from 'react-transition-group';
import { ThemeProvider } from 'styled-components';
import {
  StyledDrawerWrapper,
  StyledDrawerOverlay,
  StyledDrawerContent,
  drawerContentTransitionStyles, drawerOverlayTransitionStyles,
} from './styles';
import { stringDurationToMilliseconds } from '../../../utils/strings';
import { withResponsive } from '../../../contexts/responsive';

const DrawerComponent = forwardRef(
  (
    {
      open,
      size,
      position,
      onDismiss,
      backgroundColor,
      children,
      mountOnEnter,
      className,
      style,
      onDismissed,
      globalTheme,
      mode: drawerMode,
      theme: { mode, drawer: { transition: { duration }}},
      ...rest
    },
    ref
  ) => {
    const attrs = {
      mode: (drawerMode) ? drawerMode : mode,
      drawerMode,
      open,
      size,
      position
    };

    return (
      <Transition in={open} timeout={stringDurationToMilliseconds(duration)} mountOnEnter={mountOnEnter} onExited={onDismissed}>
        {state => (
          <StyledDrawerWrapper ref={ref} attrs={attrs}>
            <StyledDrawerContent attrs={attrs} style={{...drawerContentTransitionStyles(position)[state]}}>
              <ThemeProvider theme={{...globalTheme, mode: attrs.mode}}>
                {children}
              </ThemeProvider>
            </StyledDrawerContent>
            <StyledDrawerOverlay open={open} onClick={onDismiss} style={{...drawerOverlayTransitionStyles[state]}}/>
          </StyledDrawerWrapper>
        )}
      </Transition>
    );
  }
);

export default withResponsive(DrawerComponent);
