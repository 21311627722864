import React, { useContext } from 'react';
import FirebaseContext from './index';

export const withFirebase = Component => (props) => (
  <FirebaseContext.Consumer>
    {context => <Component {...props} {...context} />}
  </FirebaseContext.Consumer>
);


export default withFirebase;
