import React, { useEffect, useRef, useState } from 'react';
import { FirebaseProvider } from 'react-recoil-firebase';
import { RecoilRoot } from 'recoil';
import { Loader, ThemeProvider } from 'shared-ui';
import { getFirebase } from 'web-app/src/database/firebase';
import ConditionalWrapper from 'shared-ui/src/components/helpers/ConditionalWrapper';
import { BaseStyles } from 'shared-ui/src/utils/styles';
import theme from 'shared-assets/theme';

const rrfConfig = {
  userProfile: 'profiles',
  enableClaims: true,
  updateProfileOnLogin: false,
  enableRedirectHandling: false, // TODO: Need to implement this...
  profileFactory: ({user}) => {
    let { displayName, avatarUrl = '', email } = user;
    displayName = (displayName) ? displayName : '';
    const refId = localStorage.getItem('refId');
    localStorage.removeItem('refId');
    const profile = {
      displayName,
      status: 'pending',
      email,
      avatarUrl
    };
    if(refId) profile.refId = refId;

    return profile;
  }
};

const App = ({children, wrapInTheme}) => {
  const mounted = useRef(false);
  const [firebaseReady, setFirebaseReady] = useState(null);

  useEffect(() => {
    if(!mounted.current) {
      mounted.current = true;
      const lazyApp = import('firebase/app');
      const lazyAuth = import('firebase/auth');
      const lazyDatabase = import('firebase/auth');
      const lazyFirestore = import('firebase/firestore');
      const lazyStorage = import('firebase/storage');

      Promise.all([lazyApp, lazyDatabase, lazyAuth, lazyFirestore, lazyStorage]).then(([firebase]) => {
        setFirebaseReady(getFirebase(firebase));
      });
    }
  });

  if(!firebaseReady) return null;

  // TODO: Gatsby already provides theme + base styles. need a conditional?

  /*
  <ThemeProvider theme={theme}>
    <BaseStyles/>
    <React.Suspense fallback={<Loader overlay={false} fixed={true}/>}>
      {children}
    </React.Suspense>
  </ThemeProvider>
   */

  const SuspenseChildren = () => (
    <React.Suspense fallback={<Loader overlay={false} fixed={true}/>}>
      {children}
    </React.Suspense>
  );

  return (
    <RecoilRoot>
      <FirebaseProvider config={rrfConfig} firebase={firebaseReady}>
        <ConditionalWrapper condition={wrapInTheme} wrapper={(wrapperChildren) => (
          <ThemeProvider theme={theme}>
            <BaseStyles/>
            {wrapperChildren}
          </ThemeProvider>
        )}>
          <SuspenseChildren/>
        </ConditionalWrapper>
      </FirebaseProvider>
    </RecoilRoot>
  );
};

App.defaultProps = {
  wrapInTheme: true
};

export default App;
