import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useIsEqualRef, useLoadingValue } from './utils';
import { firestoreDataState } from '../../atoms/firestore';

export const useDocument = (key, docRef, options) => {
  const idField = options ? options.idField : undefined;
  const { error, loading, reset, setError, setValue, data } = useLoadingValue({key, idField});
  const ref = useIsEqualRef(docRef, reset);

  useEffect(() => {
    if (!ref.current) {
      setValue(undefined);
      return;
    }

    const listener = options && options.snapshotListenOptions
      ? ref.current.onSnapshot(options.snapshotListenOptions, setValue, setError)
      : ref.current.onSnapshot(setValue, setError);
    return () => {
      listener();
    };
  }, [ref.current]);

  return { data, loading, error };
};

export const useDocumentData = (key, query, options) => {
  useDocument(key, query, options);
  const { error, loading, data } = useRecoilValue(firestoreDataState(key));
  return { data, loading, error };
};

/*
export const useDocument = (docRef, options) => {
    const { error, loading, reset, setError, setValue, value } = useLoadingValue();
    const ref = useIsEqualRef(docRef, reset);
    useEffect(() => {
        if (!ref.current) {
            setValue(undefined);
            return;
        }
        const listener = options && options.snapshotListenOptions
            ? ref.current.onSnapshot(options.snapshotListenOptions, setValue, setError)
            : ref.current.onSnapshot(setValue, setError);
        return () => {
            listener();
        };
    }, [ref.current]);
    return [value, loading, error];
};
 */
