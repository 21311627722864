import React, { forwardRef, useState } from 'react';
import { StyledLogo } from 'shared-ui/src/components/ui/Logo/styles';
import ConditionalWrapper from 'shared-ui/src/components/helpers/ConditionalWrapper';
import { getCSSThemeMode } from 'shared-ui/src/utils/utils';

const LogoComponent = forwardRef(
  (
    {
      children,
      to,
      link: Link,
      theme,
      ...rest},
    ref
  ) => {
    const mode = getCSSThemeMode();
    const { logo } = theme;
    const { image: { default: defaultImage, hover: hoverImage} } = logo;
    const DefaultImage = defaultImage[mode];
    const HoverImage = hoverImage[mode];

    const DefaultImageLight = defaultImage['light'];
    const HoverImageLight = hoverImage['light'];
    const DefaultImageDark = defaultImage['dark'];
    const HoverImageDark = hoverImage['dark'];

    const [hover, setHover] = useState(false);

    // TODO: Render both logos & control via css.... need a display property for logo...

    return (
      <StyledLogo ref={ref} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <ConditionalWrapper
          condition={(Link)}
          wrapper={(children) => <Link to={to}>{children}</Link>}
          defaultWrapper={(children) => <a href={to}>{children}</a>}
        >
          {
            hover ?
              <>
                <HoverImageLight className={`light-logo`}/>
                <HoverImageDark className={`dark-logo`}/>
              </> : <>
                <DefaultImageLight className={`light-logo`}/>
                <DefaultImageDark className={`dark-logo`}/>
              </>
          }
        </ConditionalWrapper>
      </StyledLogo>
    );
  }
);

//{hover ? <HoverImage/> : <DefaultImage/>}

export default LogoComponent;
