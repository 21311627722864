import { firebaseDevConfig } from './credentials';

/*
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firebaseDevConfig } from './credentials';

firebase.initializeApp(firebaseDevConfig);

export { firebase as default };
*/

// TODO: Need to sort this.
/* NEED TO SORT FIREBASE LOADING ASYNC */

let firebaseInstance;
export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebase.initializeApp(firebaseDevConfig);
  firebaseInstance = firebase;

  return firebase;
};
