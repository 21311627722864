import React from 'react';
import PropTypes from 'prop-types';
import Drawer from './component';
import { withTheme } from 'styled-components';

Drawer.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.oneOf(['top','bottom','left','right']),
  onDismiss: PropTypes.func.isRequired,
  onDismissed: PropTypes.func,
  mode: PropTypes.oneOf(['light', 'dark']),
  mountOnEnter: PropTypes.bool,
};

Drawer.defaultProps = {
  size: '300px',
  position: 'left',
  mountOnEnter: true,
  onDismissed: () => null
};

export default withTheme(Drawer);
