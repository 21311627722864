import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useLoadingValue } from './utils';
import { authDataState } from '../../atoms/auth';

const defaultOptions = { listen: false, enableClaims : false };

export const useAuthState = (auth, options = {}) => {
  options = { ...defaultOptions, ...options };
  const { error, loading, setError, setValue, user, claims, loggedIn } = useLoadingValue(options);

  useEffect(() => {
    if(options.listen) {
      const listener = auth.onAuthStateChanged(setValue, setError);
      return () => {
        listener();
      };
    }
  }, [auth]);

  return { user, claims, loading, error, loggedIn };
};

export const useAuthStateData = (auth, options = {}) => {
  options = { ...defaultOptions, ...options };
  if(options.listen) useAuthState(auth, options);
  const { error, loading, user, claims, loggedIn } = useRecoilValue(authDataState);
  return { user, claims, loading, error, loggedIn };
};
