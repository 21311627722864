export const freezeAuthAtomData = ({...rest}) => {
  return {
    ...rest
  }
};

// TODO: Need a better function to strip everything from user.
export const userToData = ({uid, email, displayName, avatarUrl, ...rest}) => {
  return {
    uid,
    email,
    displayName,
    avatarUrl
  }
};
