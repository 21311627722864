import React, { useEffect } from 'react';
import { document } from 'browser-monads';

const bodyClassName = (className) => {
  useEffect(() => {
    document.body.className = document.body.className + ' ' + className;
    return () => {
      document.body.className = document.body.className.replace(className,'');
    }
  });
};

export default bodyClassName;
