const defaultLoginOptions = {
  provider: 'password',
  email: '',
  password: ''
};

export const updateProfileFromFactory = async (firebase, { profileFactory, userProfile }, user, profile = {}) => {
  try {
    const profileData = await profileFactory(user, profile);
    await firebase.firestore().doc(`${userProfile}/${user.user.uid}`).set(profileData,  { merge: true });
    return Promise.resolve(profileData);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const login = async (firebase, config, options = {}) => {
  options = { ...defaultLoginOptions, ...options };

  try {
    let user;
    let profile;
    await firebase.auth().signOut(); // Required to prevent insufficient permissions. // TODO: Probably shouldn't need this.

    if(options.provider === 'password') {
      user = await firebase.auth().signInWithEmailAndPassword(options.email, options.password);
      if(config.updateProfileOnLogin) {
        await updateProfileFromFactory(firebase, config, user);
      }
    }

    if(options.provider === 'google') {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      user = await firebase.auth().signInWithPopup(googleAuthProvider);
    }

    // Profile factory
    if(user && (config.updateProfileOnLogin || user.additionalUserInfo.isNewUser)) {
      const profile = await updateProfileFromFactory(firebase, config, user);
    }

    return Promise.resolve({user, profile});
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createUser = async (firebase, config, options = {}) => {
  options = { ...defaultLoginOptions, ...options };
  const { profile: profileParams } = options;

  try {
    let user;
    // await firebase.auth().signOut(); // Required to prevent insufficient permissions. // TODO: Probably shouldn't need this.
    user = await firebase.auth().createUserWithEmailAndPassword(options.email, options.password);
    // await firebase.auth().signInWithEmailAndPassword(options.email, options.password);

    const profile = await updateProfileFromFactory(firebase, config, user, profileParams); // TODO: Permissions missing. User probably hasn't had time to be authenticated. Send credentials through?
    //return Promise.resolve();
    return Promise.resolve({user, profile});
  } catch (e) {
    return Promise.reject(e);
  }
};
