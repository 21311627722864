import styled, { css } from 'styled-components';
import { sizeToStyle } from 'shared-ui/src/utils/utils';
import { globalStyles } from '../../../themes/base.plain';
import { StyledThemeModeToggle } from '../../navigation/ThemeModeToggle/styles';
import { StyledMenuItemLink, StyledMenuItemOnly } from '../Menu/styles';
import { customCSSStyle, marginSpacingStyle } from '../../../utils/styles';

const headerStyles = ({attrs: { fixed }, theme}) => {
  const { header } = theme;
  const { height, logoHeight, menuItemsLineHeight, contained, shadowOnScroll, shadowScrollHeight, styles } = header;
  const { background, boxShadow, borderBottomColor, borderWidth } = styles;
  const position = (fixed) ? 'fixed' : 'relative';
  const zIndex = (fixed) ? 7 : 1;

  return css`
    width: 100%;
    top:0;
    left:0;
    position: ${position};
    background: ${background};
    border-bottom-color: ${borderBottomColor};
    border-bottom-style: solid;
    border-bottom-width: ${sizeToStyle(borderWidth)};
    height: ${sizeToStyle(height)};
    z-index: ${zIndex};
    
    > div {
      display: flex;
      align-items: center;
      height: ${sizeToStyle(height)};
    }
  `;
};

export const StyledHeader = styled.div`
  ${props => headerStyles(props)}
`;

export const StyledHeaderLogo = styled.div`
  margin-right: calc(${({theme: { header: { menu: { horizontalPadding } }}}) => sizeToStyle(horizontalPadding)} * 2);
`;

export const StyledHeaderMenu = styled.div`
  
  ${StyledMenuItemLink}, ${StyledMenuItemOnly} {
    padding: 0 ${({theme: { header: { menu: { horizontalPadding } }}}) => sizeToStyle(horizontalPadding)};
    margin: 0 ${({theme: { header: { menu: { horizontalMargin } }}}) => sizeToStyle(horizontalMargin)};
    line-height: ${({theme: { header: { menu: { lineHeight } }}}) => sizeToStyle(lineHeight)};
    
    ${props => customCSSStyle(props.theme, props.theme.header.menu.css)}
    
  }
  
`;

export const StyledHeaderMenuLeft = styled(StyledHeaderMenu)`
  margin-right: auto;
`;

export const StyledHeaderMenuRight = styled(StyledHeaderMenu)`
  margin-left: auto;
`;

export const StyledHeaderThemeToggle = styled.div`
  ${props => !props.attrs.isMobile && css`margin-left: calc(${({theme: { header: { menu: { horizontalPadding } }}}) => sizeToStyle(horizontalPadding)} * 2)`};
  justify-self: right;
  ${props => customCSSStyle(props.theme, props.theme.header.menu.css)}
`;

export const StyledHeaderMenus = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMobileHeader = styled.div`
  ${props => marginSpacingStyle(props.theme, 'base', true)}
  
  ${StyledHeaderThemeToggle} {
    ${props => marginSpacingStyle(props.theme, { bottom: 'base' })}
  }
  
`;
