import Header from 'shared-ui/src/components/ui/Header/component';
import { CONTAINER_SIZE_DEFAULT } from 'shared-ui/src/components/ui/Container/constants';
import { withTheme } from 'styled-components';
import { withResponsive } from '../../../contexts/responsive';

Header.defaultProps = {
  type: CONTAINER_SIZE_DEFAULT, // fluid, narrow, tiny
  center: true,
  flush: false,
  logo: null,
  menuLeft: null,
  menuRight: null,
  mobileMenu: null
};

export default withTheme(withResponsive(Header));
