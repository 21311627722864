export const defaultFirebaseProviderConfig = {
  userProfile: 'profiles',
  enableClaims: false,
  updateProfileOnLogin: false,
  enableRedirectHandling: false,
  profileFactory: (user) => {
    let { displayName, avatarUrl, email } = user;
    return {
      displayName,
      avatarUrl,
      email
    };
  }
};
