import styled, { css } from 'styled-components';
import { sizeToStyle } from 'shared-ui/src/utils/utils';
import { globalStyles } from '../../../themes/base.plain';
import { StyledThemeModeToggle } from '../../navigation/ThemeModeToggle/styles';
import { transition } from '../../../utils/mixins';
import { customCSSStyle } from '../../../utils/styles';

/*
menu: {
    transition: {
      timing: globalStyles.animation.timing['ease-in-out'],
      duration: globalStyles.animation.duration.base,
      properties: ['color']
    },
    lineHeight: 30,
    horizontalPadding: globalStyles.spacing.sm,
    fontSize: globalStyles.fontSize.base,
    fontWeight: globalStyles.font.fontWeight.thick,
    color: {
      light: themeColors['text']['light'],
      dark: themeColors['text']['dark']
    },
    colorActive: {
      light: themeColors['brand'],
      dark: themeColors['brand']
    }
  }
 */

const menuStyles = ({attrs: { type }, theme: { menu }}) => {
  const { horizontalPadding, horizontalMargin, verticalMargin, active: { color: colorActive } } = menu;

  if(type === 'horizontal') {
    return css`
      display: flex;
      margin: 0 -calc(${sizeToStyle(horizontalPadding)} + ${sizeToStyle(horizontalMargin)});
    `;
  } else {
    return css`
      display: block;
      margin: calc(-${sizeToStyle(verticalMargin)}) 0;
    `;
  }
};

export const StyledMenu = styled.div`
  ${props => menuStyles(props)}
`;

const menuItemStyles = ({attrs: { type, hasClickEvent, active }, theme}) => {
  const { menu } = theme;
  const { css: customCss, lineHeight, horizontalPadding, horizontalMargin, verticalMargin, fontSize, fontWeight, color, active: { color: colorActive, css: activeCustomCss } } = menu;

  const activeCss = css`
    cursor: pointer;
    color: ${colorActive};
    ${activeCustomCss && customCSSStyle(theme, activeCustomCss)}
  `;

  let typeStyles = css`
    margin: 0 ${sizeToStyle(horizontalMargin)};
    display: inline-block;
  `;

  if(type === 'vertical') {
    typeStyles = css`
      margin: ${sizeToStyle(verticalMargin)} 0;
      display: block;
    `;
  }

  return css`
    line-height: ${sizeToStyle(lineHeight)};
    padding: 0 ${sizeToStyle(horizontalPadding)};
    color: ${color};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    ${transition(menu.transition)};
    text-decoration: none;
    ${typeStyles}
    
    ${customCss && customCSSStyle(theme, customCss)}
    ${(active) && activeCss}
    ${(hasClickEvent) && css`
      &:hover, &:active, &:focus {
        ${activeCss}
      }
    `}
    
   
  `;
};

export const StyledMenuItem = styled.div`
  
`;


export const StyledMenuItemLink = styled.a`
   ${props => menuItemStyles(props)}
`;

export const StyledMenuItemOnly = styled.span`
   ${props => menuItemStyles(props)}
`;
