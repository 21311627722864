import React, { useEffect } from 'react';
import { useIsEqualRef } from '../hooks/firestore/utils';
import { authDataState } from '../atoms/auth';
import { useSetRecoilState } from 'recoil';
import { freezeAuthAtomData } from '../hooks/auth/utils';
import { useDocument } from '../hooks/firestore/useDocument';

const ProfileListener = ({firebase, config: { userProfile }, user}) => {
  useDocument(
    'profile',
    firebase.firestore().doc(`${userProfile}/${user.uid}`)
  );

  return null;
};

export default ProfileListener;

/*
const ProfileListener = ({firebase, config: { userProfile }, user}) => {
  const setAuthData = useSetRecoilState(authDataState);

  const setValue = (snapshot) => {
    setAuthData((oldState) => (freezeAuthAtomData({
      ...oldState,
      profile: (snapshot && snapshot.exists) ? snapshot.data() : null
    })));
  };

  console.log(user.uid);
  const docRef = firebase.firestore().doc(`${userProfile}/${user.uid}`);
  const ref = useIsEqualRef(docRef);

  useEffect(() => {
    if (!ref.current) {
      setValue(undefined);
      return;
    }

    const listener = ref.current.onSnapshot(setValue);

    return () => {
      listener();
    };
  }, [ref.current]);


  return null;
};

export default ProfileListener;
*/
