export const firebaseDevConfig = {
  apiKey: 'AIzaSyDiTC7e35f7kwCBuTVnrzB3-3OwH4oN0GM',
  authDomain: 'desk-warrior-test.firebaseapp.com',
  projectId: 'desk-warrior-test',
  storageBucket: 'desk-warrior-test.appspot.com',
};

export const firebaseProdConfig = {
  apiKey: 'AIzaSyDiTC7e35f7kwCBuTVnrzB3-3OwH4oN0GM',
  authDomain: 'desk-warrior-test.firebaseapp.com',
  projectId: 'desk-warrior-test',
  storageBucket: 'desk-warrior-test.appspot.com',
};
