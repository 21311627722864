import { isObject } from 'shared-ui/src/utils/objects';

export const singleSnapshotToData = (snapshot, idField = 'id') => {
  if (!snapshot.exists) {
    return undefined;
  }
  return Object.assign(Object.assign({}, snapshot.data()), (idField ? { [idField]: snapshot.id } : null));
};

export const snapshotToData = (snapshot, idField = 'id') => {
  if (!snapshot) return undefined;
  if(snapshot.docs) return snapshot.docs.map(doc => singleSnapshotToData(doc, idField));
  if(snapshot.exists) return singleSnapshotToData(snapshot, idField);
  return undefined;
};

export const freezeFirestoreAtomData = ({data, ...rest}) => {
  const frozenData = data && isObject(data) ? Object.freeze(data) : data && Array.isArray(data) ? data.map(v => Object.freeze(v)) : null;
  return {
    data: frozenData,
    ...rest
  }
};
