import { useRecoilState, useSetRecoilState } from 'recoil';
import { authState, defaultAuthState, authDataState, defaultAuthDataState } from '../../../atoms/auth';
import { userToData } from './helpers';

// TODO: Use this approach elsewhere...

export default ({enableClaims}) => {
  //const state = (useData) ? authDataState : authState;
  //const defaultState = (useData) ? defaultAuthDataState : defaultAuthState;

  const [auth, setAuth] = useRecoilState(authState);
  const setAuthData = useSetRecoilState(authDataState);
  const { user, claims, loading, error, loggedIn } = auth;

  const reset = () => {
    setAuth(defaultAuthState);
    setAuthData(defaultAuthDataState);
  };

  const setError = (error) => {
    setAuth((oldState) => ({...oldState, loading: false, error, loggedIn: false}));
    setAuthData((oldState) => ({...oldState, loading: false, error, loggedIn: false}));
  };

  const setValue = async (user) => {
    // Get claims
    let claims = null;
    if(enableClaims && user) {
      const idTokenResult = await user.getIdTokenResult();
      if(idTokenResult && idTokenResult.claims) {
        claims = idTokenResult.claims;
      }
    }

    const loggedIn = (user !== null && user !== undefined);
    setAuth((oldState) => ({...oldState, loading: false, loggedIn, user, claims}));
    setAuthData((oldState) => ({...oldState, loading: false, loggedIn, claims, user: (user) ? userToData(user) : null}));
  };

  return {
    error,
    loading,
    reset,
    setError,
    setValue,
    user,
    claims,
    loggedIn
  };
};
