import React from 'react';
import { Router } from '@reach/router';
import Route from 'web-app/src/containers/app/Router/components/Route';

const RouterComponent = ({routes}) => {
  return (
    <Router>
      {Object.values(routes).map((route, i) => <Route key={`route-${i}`} {...route}/>)}
    </Router>
  );
};

export default RouterComponent;
